import React, { useState, forwardRef, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Modal, Grid, Segment, Button, Input } from "semantic-ui-react";

import axios from "axios";

// Common
import CardLayout from "react-lib/apps/common/CardLayout";
import ModConfirm from "react-lib/apps/common/ModConfirm";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ComboBox from "react-lib/apps/common/ComboBox";
import ModInfo from "react-lib/apps/common/ModInfo";
import CardEncounterList from "react-lib/apps/common/CardEncounterList";
import PatientIdentification from "react-lib/apps/common/PatientIdentification";
import FrameChrome from "react-lib/apps/common/FrameChrome";
import SearchBox from "react-lib/apps/common/SearchBox";
import PureReactTable from "./PureReactTable";
import getPdfMake from "react-lib/appcon/common/pdfMake";

// Util
import { beToAd, beStringToAdString } from "react-lib/utils/dateUtils";
import { vcMessenger } from "react-lib/compat/vc-websocket";
import moment from "moment";
import _ from "react-lib/compat/lodashplus";
import { printURL } from "react-lib/utils/printURL";

// Config
import CONFIG from "config/config";

// PDF
import {
  AddHeaderPrintListTH,
  AddHeaderPrintListEN,
  AddHeaderSapiensInfoTH,
} from "./pdfPrintList/AddHeaderSapiensPrintList";
import { default as FormLivingWill } from "./pdfPrintList/FormLivingWill";
import { default as FormConsentPersonalHealth } from "./pdfPrintList/FormConsentPersonalHealth";
import { default as FormConsentDrugScreenTH } from "./pdfPrintList/FormConsentDrugScreenTH";
import { default as FormConsentDrugScreenEN } from "./pdfPrintList/FormConsentDrugScreenEN";
import { default as FormConsentNarcoticUseTH } from "./pdfPrintList/FormConsentNarcoticUseTH";
import { default as FormConsentNarcoticUseEN } from "./pdfPrintList/FormConsentNarcoticUseEN";
import { default as FormConsentHIVTestTH } from "./pdfPrintList/FormConsentHIVTestTH";
import { default as FormConsentHIVTestEN } from "./pdfPrintList/FormConsentHIVTestEN";
import { default as FormConsentBloodTransfusionTH } from "./pdfPrintList/FormConsentBloodTransfusionTH";
import { default as FormConsentBloodTransfusionEN } from "./pdfPrintList/FormConsentBloodTransfusionEN";
import { default as FormConsentPreSedationTH } from "./pdfPrintList/FormConsentPreSedationTH";
import { default as FormConsentPreSedationEN } from "./pdfPrintList/FormConsentPreSedationEN";
import { default as FormConsentOperatingTH } from "./pdfPrintList/FormConsentOperatingTH";
import { default as FormConsentTreatmentProcedure } from "./pdfPrintList/FormConsentTreatmentProcedure";
import { default as FormNewPatientRegistration } from "./pdfPrintList/FormNewPatientRegistration";
import { default as FormConsentAMATH } from "./pdfPrintList/FormConsentAMATH";
import { default as FormConsentAMAEN } from "./pdfPrintList/FormConsentAMAEN";
import { default as FormDNRTH } from "./pdfPrintList/FormDNRTH";
import { default as FormDNREN } from "./pdfPrintList/FormDNREN";
import { default as FormLivingWillTH } from "./pdfPrintList/FormLivingWillTH";
import { default as FormLivingWillEN } from "./pdfPrintList/FormLivingWillEN";
import FormPatientStickerSingle from "./pdfPrintList/FormPatientStickerSingle";
import FormPriceEvaluate from "./pdfPrintList/FormPriceEvaluate";
import FormPriceEvaluateTH from "./pdfPrintList/FormPriceEvaluateTH";
import FormPriceEvaluateEN from "./pdfPrintList/FormPriceEvaluateEN";
import FormConsentOperatingEN from "./pdfPrintList/FormConsentOperatingEN";
import FormPriceEstimateTH from "./pdfPrintList/FormPriceEstimateTH";

// Const c.
const HOST = `${CONFIG.API_HOST}`;
const isEnableHeader = CONFIG.ENABLE_HEADER_PRINTLIST;

const LANGUAGE_USAGE = {
  TH: "TH",
  EN: "EN",
};

// อ้างจาก CardPatientInfo
const MARRIAGE_STATUS = [
  { id: "S", text: "โสด" },
  { id: "M", text: "สมรส" },
  { id: "E", text: "หมั้น" },
  { id: "D", text: "หย่า" },
  { id: "W", text: "หม้าย" },
  { id: "A", text: "แยกกันอยู่" },
];

// อ้างจาก core Patient
const GENDER_LIST = {
  "M": { name_th: "ชาย", name_en: "Male" },
  "F": { name_th: "หญิง", name_en: "Female" },
  "U": { name_th: "ไม่แน่ชัด", name_en: "Undetermined" },
  "PM": { name_th: "Male (Not Approved)", name_en: "Male (Not Approved)" },
  "PF": { name_th: "Female (Not Approved)", name_en: "Female (Not Approved)" },
  "MM": { name_th: "Men who have sex with men", name_en: "Men who have sex with men" },
  "TG": { name_th: "Transgender", name_en: "Transgender"}
}

const FALLBACK_PDF_PATHS: Record<string, string> = {
  price_evaluate: "/static/pdf/printList/price_evaluate.pdf",
  price_evaluate_en: "/static/pdf/printList/price_evaluate_en.pdf",
  price_evaluate_th: "/static/pdf/printList/price_evaluate_th.pdf",
};

/* Note About Inject Data in PDF Form
- FormConsentPreSedationTH : Inject Table Detail Anesthetic ท้ายสุดของ Content
- FormConsentPreSedationEN : Inject Table Detail Anesthetic ท้ายสุดของ Content
- FormConsentTreatmentProcedure : Inject Table Finger Print หลัง Paragraph "ทั้งนี้ข้าพเจ้า เข้าใจ......เป็นหลักฐาน"
- FormNewPatientRegistration : มีการ Inject Checkbox, Square Box และ Fix Position เช่น เลขบัตรประชาชน, เลขไปรษณีย์ใน Square Box
*/

const CardPrintList = forwardRef((props: any, ref) => {
  const [taskTracking, setTaskTracking] = useState(null);
  const [openModEncounterList, setOpenModEncounterList] = useState(false);
  const [encounterId, setEncounterId] = useState(props.encounterId);
  const [encounterList, setEncounterList] = useState([]); //* as default options for SearchBox
  const [documentType, setDocumentType] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<string | null>(null); //* pdf
  const [message, setMessage] = useState<any>({}); //* msg from vcMessenger
  const [patientInfo, setPatientInfo] = useState(props.patientInfo); // for Search Encounter
  const [emrId, setEmrId] = useState(props.patientData?.EMR?.emr_id); // for Search Encounter
  const [encounterNumber, setEncounterNumber] = useState(props.encounterNumber); // for Search Encounter
  const [doctorName, setDoctorName] = useState(props.patientInfo?.doctor_name); // for Search Encounter
  const [isSearchDocumentLoading, setIsSearchDocumentLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  //* show error and success
  const [openModInfo, setOpenModInfo] = useState(false);
  const [modInfoTitle, setModInfoTitle] = useState("");
  const [modInfoTitleColor, setModInfoTitleColor] = useState("");
  const [modInfoText, setModInfoText] = useState("");

  //* Mod Show task progress
  const [openModInfoTask, setOpenModInfoTask] = useState(false);
  const [modInfoTaskText, setModInfoTaskText] = useState("");

  //* Don't know what this is
  const [getPrinted, setGetPrinted] = useState(false);

  //* Cant find use case yet
  const [openModConfirm, setOpenModConfirm] = useState(false);
  const [modConfirmTitle, setModConfirmTitle] = useState("แจ้งเตือน!!!");
  const [reasonChoices, setReasonChoices] = useState([]);
  const [modConfirmError, setModConfirmError] = useState(null);

  const isMounted = useRef(false);
  const sbxEncounterRef = useRef();
  const reasonRef = useRef();

  useEffect(() => {
    isMounted.current = true;
    getDocumentType();
    vcMessenger.onMessage((msg: any) => {
      try {
        setMessage(msg);
      } catch (e) {}
    });
    getReason();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (message.event === "DPI_DOCTOR_ORDER_PRINT") {
      if (message.task_id === taskTracking) {
        handleTaskTrackingStatus({ data: message });
      }
    }
  }, [message]);

  useEffect(() => {
    if (!props.encounterId) {
      setEncounterId(props.patientInfo?.encounter_id);
    }
  }, [props.encounterId]);

  useEffect(() => {
    if (props.encounterNumber) {
      handleSearchEncounter({ searchText: props.encounterNumber });
    }
  }, [props.encounterNumber]);

  useEffect(() => {
    if (taskTracking) {
      var task = setInterval(() => {
        getTaskTracking();
      }, 15000);
    }
    return () => clearInterval(task);
  }, [taskTracking]);

  useEffect(() => {
    getRegDataList();
  }, [props.masterOptions]);

  const clear = () => {
    setFile(null);
    setSelectedRow(null);
    getDocumentType();
    setTaskTracking(null);
    setGetPrinted(false);
    // frmError.clear()
    // default Data
    setEncounterId(props.encounterId);
    setPatientInfo(props.patientInfo);
    setEmrId(null);
    setEncounterNumber(null);
    setDoctorName(null);
  };

  const getReason = async () => {
    const [data, error] = await props.controller.getClinicalTerm({
      type: "REASON_PATIENT_CARD",
    });
    if (isMounted.current) {
      if (data) {
        setReasonChoices(data);
      }
    }
  };

  const getPdfBase64 = async (url: string): Promise<string> => {
    try {
      const response = await axios.get(url, { responseType: "arraybuffer" });

      return Buffer.from(response.data, "binary").toString("base64");
    } catch (error) {
      console.error(`Failed to fetch PDF:`, error);

      return "";
    }
  };

  const getPdfBlob = async (url: string): Promise<Blob | null> => {
    try {
      const response = await axios.get(url, { responseType: "arraybuffer" });

      return new Blob([response.data], { type: response.headers["content-type"] });
    } catch (error) {
      console.error(`Failed to fetch PDF:`, error);

      return null;
    }
  };

  const handleTaskTrackingStatus = ({ data }: any = {}) => {
    // console.log("Print List Data: ", data);
    if (data) {
      if (data.status === TASK_TRACKING_STATUS.COMPLETED && !getPrinted) {
        setTaskTracking(null);
        getPrintedDocument();
        setGetPrinted(true);
        setOpenModInfoTask(false);
      } else if (data.status === TASK_TRACKING_STATUS.FAILED) {
        setTaskTracking({ taskId: data.task_id } as any);
        setModInfoTaskText(data.task_id);
        setModInfoTitle("ผิดพลาด");
        setModInfoTitleColor("red");
      } else if (data.status === TASK_TRACKING_STATUS.CANCELED) {
        setTaskTracking(null);
        setModInfoText(data.current_progress_description);
        setModInfoTitle("ยกเลิก");
        setModInfoTitleColor("red");
        setOpenModInfoTask(false);
        setOpenModInfo(true);
      } else if (data.status === TASK_TRACKING_STATUS.IN_PROGRESS) {
        setModInfoTaskText(
          (
            <div style={{ textAlign: "left", padding: 0 }}>
              <div>กรุณารอสักครู่ </div>
              <div>
                Status:{" "}
                {`${data.status} ${data.current_progress ? data.current_progress : data.progress}%`}
              </div>
              For IT: task_id = {data.task_id}
            </div>
          ) as any
        );
      } else if (!data.hasOwnProperty("status")) {
        setTaskTracking(null);
        handleGetDocumentResult({ data, preview: true });
      }
    }
  };

  const getPrintedDocument = ({ taskId }: any = {}) => {
    (reasonRef as any).current.clear();
    getTaskTracking({ taskId } as any);
  };

  const getTaskTracking = async ({ action, taskId }: any = {}) => {
    const [data, error] = await props.controller.getTaskTrackingResult({
      taskId: taskId ? taskId : taskTracking,
      action,
    });
    if (isMounted.current) {
      handleTaskTrackingStatus({ data });
    }
  };

  const getDocumentType = async () => {
    setIsLoading(true);
    const [data, error] = await props.controller.getDocumentType({
      // name: "ใบลงทะเบียนผู้ป่วยใหม่ (New Patient Registration Form)",
      printable: true,
      division: "current",
      offset: 0,
      limit: 40,
    });
    if (isMounted.current) {
      if (data) {
        setDocumentType(data.items);
      }
    }
    setIsLoading(false);
  };

  const handleSearchEncounter = async ({ searchText }: any = {}) => {
    const [data, error] = await props.controller.getEncounterSearch({
      // patientId: props.patientId, // #65599 show all encounter
      patientHn: props.patientInfo?.hn,
      search: searchText,
    });
    if (isMounted.current) {
      if (data) {
        setEncounterList(data.items);
      }
    }
    return [data, error];
  };

  // Search Document
  const handleSearchDocumentType = async ({ target }: any) => {
    const searchText = target.value;
    setSearchInput(searchText);
    const minCharacters = 3;

    if (searchText.length === 0) {
      getDocumentType();
      return;
    }
    if (searchText.length >= minCharacters) {
      debounceSearchDocument(searchText);
    }
  };

  // Debounce Search Document
  const debounceSearchDocument = _.debounce(async (inputValue: any) => {
    setIsSearchDocumentLoading(true);
    const [data, error] = await props.controller.getDocumentType({
      name: inputValue,
      printable: true,
      division: "current",
      offset: 0,
      limit: 40,
    });
    if (isMounted.current) {
      if (data) {
        setDocumentType(data.items);
      }
    }
    setIsSearchDocumentLoading(false);
  }, 500);

  const handleSelectOption = async ({ item }: any = {}) => {
    getEncounterSearchDetail({ encounterId: item.id });
  };

  const getEncounterSearchDetail = async ({ encounterId }: any = {}) => {
    const [data, error] = await props.controller.getEncounterSearchDetail({
      encounterId,
    });
    if (isMounted.current) {
      if (data) {
        setEncounterId(data.id);
        setEncounterNumber(data.number);
        setDoctorName(data.doctor_name);
      }

      // get Encounter Detail
      let encounterData = await getEncounter(encounterId);

      // console.log("Print List Encounter Data: ", encounterData);
      // get Patient Detail
      if (encounterData) {
        setEmrId(encounterData.medical_record);

        // get Patient Detail
        let patientDetailData = await getPatientDetailView({
          patientId: encounterData.patient,
        });

        // console.log("Print List PatientDetail Data: ", patientDetailData);
        if (patientDetailData) {
          setPatientInfo(patientDetailData[0]);
        }
      }
    }
  };

  const getEncounter = async (encounterId: any) => {
    const [data, error] = await props.controller.getEncounter(encounterId);
    if (data) {
      return data;
    }
  };

  const handleSelectDocType = (originalRow: any) => {
    setSelectedRow(originalRow);
    console.log("Print List Original Row: ", originalRow);
    if (originalRow?.print_api !== "/pdfmake") {
      getPrintAPI({ originalRow, preview: true } as any);
    } else {
      getPrintPDFMake({ originalRow, print: false } as any);
    }
  };

  const handlePrintAPIFail = ({ error, preview, confirm }: any = {}) => {
    if (preview) {
      clear();
    }
    setFile(null);
    if (confirm) {
      if (error) {
        setModConfirmTitle(error.message);
      }
      setOpenModConfirm(true);
    } else {
      setModInfoTitle("ผิดพลาด");
      setModInfoTitleColor("red");
      if (error) {
        if (error.message) {
          setModInfoText(error.message);
        } else {
          setModInfoText(error);
        }
      }
      setOpenModInfo(true);
    }
  };

  const getPrintAPI = async ({ originalRow, preview, confirm }: any = {}) => {
    setFile(null);
    if (!originalRow) {
      return;
    }
    setIsLoading(true);
    const [data, error, network] = await props.controller.getPrintAPI({
      printAPI: HOST + originalRow.print_api,
      patientId: props.patientId ? props.patientId : props.patientInfo?.id, // #65578 and #65580 patientId is null
      orderId: props.operatingOrderId,
      encounterId: encounterId ? encounterId : props.patientInfo?.encounter_id, // #67954 encounter id on reg menu
      jasperModule: originalRow.jasper_module,
      jasperPath: originalRow.jasper_path,
      pdf: preview,
      confirm,
      reason: reasonRef.current ? (reasonRef as any).current.getValue() : "",
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (data) {
        handleGetDocumentResult({ data, preview });
      } else if (error && ![500, 503].includes(network.response.status)) {
        handlePrintAPIFail({ error, preview, confirm });
      }
    }
  };

  const getOperatingOrderDetailByEmr = async ({ emrId }: any = {}) => {
    if (!emrId) {
      return;
    }
    const [data, error] = await props.controller.getOperatingOrderDetailByEmr({
      emrId: emrId,
    });
    // console.log("Print List Operating Order Data: ", data);
    return [data, error];
  };

  const getDoctorDetail = async ({ doctorId }: any = {}) => {
    if (!doctorId) {
      return;
    }
    const [data, error] = await props.controller.getDoctorDetail({
      doctorId: doctorId,
    });
    // console.log("Print List Doctor Detail Data: ", data);
    return [data, error];
  };

  const getDoctorInfoFromEncounter = async () => {
    let encounterData = await getEncounter(encounterId);

    if (encounterData) {
      let doctorInfo = await getDoctorDetail({
        doctorId: encounterData?.doctor,
      });

      if (doctorInfo) {
        return doctorInfo[0];
      }
    }
    return null;
  };

  const getPatientDetailView = async ({ patientId }: any = {}) => {
    if (!patientId) {
      return;
    }
    const [data, error] = await props.controller.getPatientDetailView({
      patientId: patientId,
    });
    // console.log("Print List Patient Detail Data: ", data);
    return [data, error];
  };

  const getDiagnosisMedicalRecordDetail = async ({ emrId }: any = {}) => {
    if (!emrId) {
      return;
    }
    const [data, error] = await props.controller.getDiagnosisMedicalRecordDetail({
      emrId: emrId,
    });
    // console.log("Print List Diagnosis Detail Data: ", data);
    return [data, error];
  };

  const getTreatmentDetail = async ({ code }: any = {}) => {
    if (!code) {
      return;
    }
    const [data, error] = await props.controller.getTreatmentDetail({
      code: code,
    });
    return [data, error];
  };

  const getHospitalNameForPrintList = async ({ language }: any = {}) => {
    const [data, error] = await props.controller.getHospitalNameForPrintList({
      language: language,
    });
    return [data, error];
  };

  const getPatientAllergyForPrintList = async ({ patientId }: any = {}) => {
    const [data, error] = await props.controller.getPatientAllergyForPrintList({
      patientId: patientId,
    });
    return [data, error];
  };

  // Split Date
  /* return [getDay, getMonth, getMonthName, getYear, getTime] และ [null,null] */
  const getSliceDate = (date: any, locale?: string) => {
    const defaultLocale = locale || "th";
    let getDay,
      getMonth,
      getMonthName,
      getYear,
      getTime = null;

    switch (date.length) {
      case 10:
        getDay = date.slice(0, 2);
        getMonth = date.slice(3, 5);
        getMonthName = moment(getMonth, "MM").locale(defaultLocale).format("MMMM");
        getYear = date.slice(6, 10);
        // [0] = Day, [1] = Month, [2] = MonthName, [3] = Year
        // return [getDay, getMonth, getMonthName, getYear, getTime];
        break;
      case 16:
        getDay = date.slice(0, 2);
        getMonth = date.slice(3, 5);
        getMonthName = moment(getMonth, "MM").locale(defaultLocale).format("MMMM");
        getYear = date.slice(6, 10);
        getTime = date.slice(12, 16);
        // [0] = Day, [1] = Month, [2] = MonthName, [3] = Year, [4] = Time
        // return [getDay, getMonth, getMonthName, getYear, getTime];
        break;
      case 17:
        getDay = date.slice(0, 2);
        getMonth = date.slice(3, 5);
        getMonthName = moment(getMonth, "MM").locale(defaultLocale).format("MMMM");
        getYear = date.slice(6, 10);
        getTime = date.slice(12, 17);
        // [0] = Day, [1] = Month, [2] = MonthName, [3] = Year, [4] = Time
        break;
    }
    return [getDay, getMonth, getMonthName, getYear, getTime];
  };

  // Age Calculate (CurrentDate - BirthDate) // birth and current must be formatted by using formatDateBack() before.
  /* return [yearDuration, monthDuration, dayDuration] or [null, null, null]*/
  const getAge = (birthDate: string, currentDate: string) => {
    const diffDate = moment(currentDate).diff(birthDate);
    var yearDuration = moment.duration(diffDate).years();
    var monthDuration = moment.duration(diffDate).months();
    var dayDuration = moment.duration(diffDate).days();
    // [0] = Year, [1] = Month, [2] = Day
    return [yearDuration, monthDuration, dayDuration];
  };

  // Empty Obj Check
  const emptyObjectCheck = (obj: any) => {
    return obj !== null && typeof obj === "object" && Object.keys(obj).length > 0;
  };

  // Get RegData
  const getRegDataList = async () => {
    const regDataList = {
      prenameTh: props.masterOptions?.prenameTh || [], // คำนำชื่อไทย
      prenameEn: props.masterOptions?.prenameEn || [], // คำนำชื่อ Eng
      bloodType: props.masterOptions?.bloodType || [], // หมู่เลือด
      nationality: props.masterOptions?.nationality || [], // สัญชาติ
      race: props.masterOptions?.race || [], // เชื้อชาติ
      religion: props.masterOptions?.religion || [], // ศาสนา
      belief: props.masterOptions?.belief || [], // ความเชื่อ
      marriage: MARRIAGE_STATUS, // สถานภาพสมรส
      education: props.masterOptions?.education || [], // ระดับการศึกษา
      birth_province: props.masterOptions?.province || [], // จังหวัดที่เกิด
      birth_country: props.masterOptions?.country || [], // ประเทศที่เกิด
    };
    return regDataList;
  };

  const getPrintPDFMake = async ({ originalRow, print }: any = {}) => {
    // console.log("Print List PrintPDFMake (Func): ", originalRow);
    setFile(null);
    if (!originalRow) {
      return;
    }
    setIsLoading(true);
    let docPdfDef: any = { content: [] };
    let pdfData: any = null;

    // Print/Create Date (Global)
    const currentDate = moment().format("YYYY-MM-DD, HH:mm"); // 2024-02-29, 14:30
    const christYear = moment(currentDate).format("YYYY");
    const buddhistYear = (parseInt(christYear) + 543).toString();
    const formattedDate = moment(currentDate).format("DD/MM/YYYY".replace("YYYY", buddhistYear));
    const printedDateFormTh = moment(currentDate)
      .locale("th")
      .format("DD/MM/YYYY, HH:mm".replace("YYYY", buddhistYear)); // 29/02/2567, 14:30
    const printedDateFormEn = moment(currentDate).format("DD/MM/YYYY, HH:mm"); // 29/02/2024, 14:30
    const printedDateUsageTh = printedDateFormTh.split(", "); // ["29/02/2567", "14:30"]
    const printedDateUsageEn = printedDateFormEn.split(", "); // ["29/02/2024", "14:30"]

    // Patient Info Format for Forms
    const patientNameWithoutPrefix = `${props.patientInfo?.first_name} ${props.patientInfo?.last_name}`;
    const patientNameWithoutPrefixEN = `${props.patientInfo?.first_name_en} ${props.patientInfo?.last_name_en}`;
    const patientNameWithoutPrefixTH = `${props.patientInfo?.first_name_th} ${props.patientInfo?.last_name_th}`;
    const patientNameInFormEN =
      patientNameWithoutPrefixEN !== " "
        ? patientNameWithoutPrefixEN
        : patientNameWithoutPrefixTH !== " "
        ? patientNameWithoutPrefixTH
        : patientNameWithoutPrefix;
    const patientNameInFormTH =
      patientNameWithoutPrefixTH !== " " ? patientNameWithoutPrefixTH : patientNameWithoutPrefix;
    const patientBirthdateFormEN = beStringToAdString(props.patientInfo?.birthdate);

    // Doctor Name Format for Forms
    const doctorInfo = await getDoctorInfoFromEncounter();
    const doctorNameWithPrefix = doctorInfo ? `${doctorInfo?.full_name}` : ` `;
    const doctorNameWithoutPrefixEN = doctorInfo
      ? `${doctorInfo?.first_name_en} ${doctorInfo?.last_name_en}`
      : ` `;
    const doctorNameWithoutPrefixTH = doctorInfo
      ? `${doctorInfo?.first_name} ${doctorInfo?.last_name}`
      : ` `;
    const doctorNameInFormEN =
      doctorNameWithoutPrefixEN.trim().length > 0
        ? doctorNameWithoutPrefixEN
        : doctorNameWithoutPrefixTH.trim().length > 0
        ? doctorNameWithoutPrefixTH
        : doctorNameWithPrefix;
    const doctorNameInFormTH =
      doctorNameWithoutPrefixTH.trim().length > 0
        ? doctorNameWithoutPrefixTH
        : doctorNameWithPrefix;

    // Format Address
    const formattedAddress = {
      haveAddress: emptyObjectCheck(props.patientInfo?.present_address),
      no:
        props.patientInfo?.present_address?.no !== null
          ? props.patientInfo?.present_address?.no
          : " ",
      town:
        props.patientInfo?.present_address?.town !== null
          ? props.patientInfo?.present_address?.town
          : " ",
      name:
        props.patientInfo?.present_address?.name !== null
          ? props.patientInfo?.present_address?.name
          : " ",
      street:
        props.patientInfo?.present_address?.street !== null
          ? props.patientInfo?.present_address?.street
          : " ",
      road:
        props.patientInfo?.present_address?.road !== null
          ? props.patientInfo?.present_address?.road
          : " ",
      city:
        props.patientInfo?.present_address?.city_label !== null
          ? props.patientInfo?.present_address?.city_label
          : " ",
      district:
        props.patientInfo?.present_address?.district_label !== null
          ? props.patientInfo?.present_address?.district_label
          : " ",
      province:
        props.patientInfo?.present_address?.province_label !== null
          ? props.patientInfo?.present_address?.province_label
          : " ",
      zipcode:
        props.patientInfo?.present_address?.zipcode !== null
          ? props.patientInfo?.present_address?.zipcode
          : " ",
    };

    // getSliceDate
    let createdFormDate = getSliceDate(printedDateFormTh);
    let createdFormDateEn = getSliceDate(printedDateFormEn, "en");

    // patientInfo Variable
    let patientEmrId = props.patientData?.EMR?.emr_id;
    let patientDoctorName = props.patientInfo?.doctor_name;
    let patientEncounterNumber = props.patientInfo?.encounter_no;

    // Hospital Name
    let hospitalName: any = null;

    // Patient Allegy
    let patientAllergy: any = null;

    // RegEx
    let operationRegExRaw,
      icd9RegExRaw,
      icd10RegExRaw,
      operationRegEx,
      icd9RegEx,
      icd10RegEx = null;

    // Patient Age (FormattedDate - BirthDate)
    let patientAge = getAge(beToAd(props.patientInfo?.birthdate), beToAd(formattedDate));

    // Data Selected
    if (encounterId) {
      props.encounterId = encounterId;
      props.encounterNumber = encounterNumber;
      patientEncounterNumber = encounterNumber;
      patientEmrId = emrId;
    }
    if (patientInfo) {
      props.patientInfo = patientInfo;
      patientDoctorName = doctorName;
    }

    // Condition Form
    switch (originalRow?.code) {
      case "living_will":
        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.encounterNumber
              ? props.encounterNumber
              : props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : "-",
            printDay: createdFormDate[0],
            printMonth: createdFormDate[2],
            printYear: createdFormDate[3],
            formAddress: formattedAddress,
            formatPatient: patientNameInFormTH,
            formatDoctor: doctorNameInFormTH,
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
            haveHeader: isEnableHeader,
          },
        };

        docPdfDef = await FormLivingWill(pdfData);

        if (isEnableHeader) {
          docPdfDef = await AddHeaderPrintListTH(docPdfDef, {
            createdDate: printedDateFormTh,
            formName:
              "หนังสือแสดงเจตนาไม่ประสงค์จะรับบริการสาธารณสุขที่เป็นเพียง\nเพื่อยืดการตายในวาระสุดท้ายของชีวิต หรือเพื่อยุติการทรมานจากการเจ็บป่วย",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "cons_pers_health":
        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.encounterNumber
              ? props.encounterNumber
              : props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : "-",
            printDay: createdFormDate[0],
            printMonth: createdFormDate[2],
            printYear: createdFormDate[3],
            printTime: createdFormDate[4],
            formAddress: formattedAddress,
            formatPatient: patientNameInFormTH,
            formatDoctor: doctorNameInFormTH,
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
            haveHeader: isEnableHeader,
          },
        };

        docPdfDef = await FormConsentPersonalHealth(pdfData);

        if (isEnableHeader) {
          docPdfDef = await AddHeaderPrintListTH(docPdfDef, {
            createdDate: printedDateFormTh,
            formName: "หนังสือแสดงความยินยอมให้เปิดเผยข้อมูลด้านสุขภาพ\nของบุคคลทางอิเล็กทรอนิกส์",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "cons_drug_screen_th":
        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.encounterNumber
              ? props.encounterNumber
              : props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : "-",
            formatPatient: patientNameInFormTH,
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
            signedDate: printedDateUsageTh[0],
            signedTime: `${printedDateUsageTh[1]} น.`,
            haveHeader: isEnableHeader, // For Using Checkbox
          },
        };

        docPdfDef = await FormConsentDrugScreenTH(pdfData);

        if (isEnableHeader) {
          docPdfDef = await AddHeaderPrintListTH(docPdfDef, {
            createdDate: printedDateFormTh,
            formName: "หนังสือแสดงการยินยอมตรวจหาสารเสพติด",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "cons_drug_screen_en":
        pdfData = {
          items: {
            ...props.patientInfo,
            patientBirthdateEN: patientBirthdateFormEN,
            patientEncounter: props.encounterNumber
              ? props.encounterNumber
              : props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : "-",
            formatPatient: patientNameInFormEN,
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
            signedDate: printedDateUsageEn[0],
            signedTime: printedDateUsageEn[1],
            haveHeader: isEnableHeader, // For Using Checkbox
          },
        };

        docPdfDef = await FormConsentDrugScreenEN(pdfData);

        if (isEnableHeader) {
          docPdfDef = await AddHeaderPrintListEN(docPdfDef, {
            createdDate: printedDateFormEn,
            formName: "Consent Drug Screen Test",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "cons_narcotic_use_th":
        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.encounterNumber
              ? props.encounterNumber
              : props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : "-",
            formatPatient: patientNameInFormTH,
            formatDoctor: doctorNameInFormTH,
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
            signedDate: printedDateUsageTh[0],
            signedTime: `${printedDateUsageTh[1]} น.`,
            haveHeader: isEnableHeader, // For Using Checkbox
          },
        };

        docPdfDef = await FormConsentNarcoticUseTH(pdfData);

        if (isEnableHeader) {
          docPdfDef = await AddHeaderPrintListTH(docPdfDef, {
            createdDate: printedDateFormTh,
            formName:
              "หนังสือแสดงความยินยอมรับการรักษาด้วยยารักษาอาการปวด\nชนิดเสพติดในอาการปวดเรื้อรังที่ไม่ใช่มะเร็ง",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "cons_narcotic_use_en":
        pdfData = {
          items: {
            ...props.patientInfo,
            patientBirthdateEN: patientBirthdateFormEN,
            patientEncounter: props.encounterNumber
              ? props.encounterNumber
              : props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : "-",
            formatPatient: patientNameInFormEN,
            formatDoctor: doctorNameInFormEN,
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
            signedDate: printedDateUsageEn[0],
            signedTime: printedDateUsageEn[1],
            haveHeader: isEnableHeader, // For Using Checkbox
          },
        };

        docPdfDef = await FormConsentNarcoticUseEN(pdfData);

        if (isEnableHeader) {
          docPdfDef = await AddHeaderPrintListEN(docPdfDef, {
            createdDate: printedDateFormEn,
            formName: "Consent for Narcotic Use In Chronic Non-Cancer Pain",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "cons_hiv_test_th":
        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.encounterNumber
              ? props.encounterNumber
              : props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : "-",
            formatPatient: patientNameInFormTH,
            formatDoctor: doctorNameInFormTH,
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
            signedDate: printedDateUsageTh[0],
            signedTime: `${printedDateUsageTh[1]} น.`,
            haveHeader: isEnableHeader, // For Using Checkbox
          },
        };

        docPdfDef = await FormConsentHIVTestTH(pdfData);

        if (isEnableHeader) {
          docPdfDef = await AddHeaderPrintListTH(docPdfDef, {
            createdDate: printedDateFormTh,
            formName: "หนังสือแสดงความยินยอมการตรวจวินิจฉัยเพื่อหาเชื้อเอชไอวี",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "cons_hiv_test_en":
        pdfData = {
          items: {
            ...props.patientInfo,
            patientBirthdateEN: patientBirthdateFormEN,
            patientEncounter: props.encounterNumber
              ? props.encounterNumber
              : props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : "-",
            formatPatient: patientNameInFormEN,
            formatDoctor: doctorNameInFormEN,
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
            signedDate: printedDateUsageEn[0],
            signedTime: printedDateUsageEn[1],
            haveHeader: isEnableHeader, // For Using Checkbox
          },
        };

        docPdfDef = await FormConsentHIVTestEN(pdfData);

        if (isEnableHeader) {
          docPdfDef = await AddHeaderPrintListEN(docPdfDef, {
            createdDate: printedDateFormEn,
            formName: "Informed Consent to HIV Blood Test",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "cons_blood_trans_th":
        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.encounterNumber
              ? props.encounterNumber
              : props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : "-",
            formatPatient: patientNameInFormTH,
            formatDoctor: doctorNameInFormTH,
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
            signedDate: printedDateUsageTh[0],
            signedTime: `${printedDateUsageTh[1]} น.`,
            haveHeader: isEnableHeader, // For Using Checkbox
          },
        };

        docPdfDef = await FormConsentBloodTransfusionTH(pdfData);

        if (isEnableHeader) {
          docPdfDef = await AddHeaderPrintListTH(docPdfDef, {
            createdDate: printedDateFormTh,
            formName: "หนังสือแสดงความยินยอมการรับโลหิต และ/หรือ ส่วนประกอบของโลหิต",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "cons_blood_trans_en":
        pdfData = {
          items: {
            ...props.patientInfo,
            patientBirthdateEN: patientBirthdateFormEN,
            patientEncounter: props.encounterNumber
              ? props.encounterNumber
              : props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : "-",
            formatPatient: patientNameInFormEN,
            formatDoctor: doctorNameInFormEN,
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
            signedDate: printedDateUsageEn[0],
            signedTime: printedDateUsageEn[1],
            haveHeader: isEnableHeader, // For Using Checkbox
          },
        };

        docPdfDef = await FormConsentBloodTransfusionEN(pdfData);

        if (isEnableHeader) {
          docPdfDef = await AddHeaderPrintListEN(docPdfDef, {
            createdDate: printedDateFormEn,
            formName: "Consent for Blood/Blood Product Transfusion",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "cons_pre_sedat_th":
        let anesthesiaPreSedationThId,
          anesthesiaPreSedationThData,
          operatingOrderDetailPreSedationTh,
          operatingOrderDataPreSedationTh = null;

        // get Hospital Name
        let resultHospitalNameForPreSedatTh = await getHospitalNameForPrintList({
          language: LANGUAGE_USAGE.TH,
        });

        if (resultHospitalNameForPreSedatTh) {
          hospitalName = resultHospitalNameForPreSedatTh[0]?.result?.core_HOSPITAL_NAME;
        } else {
          hospitalName = " ";
        }

        if (patientEmrId) {
          const resultOperatingOrderPreSedationTh = await getOperatingOrderDetailByEmr({
            emrId: patientEmrId,
          });
          if (resultOperatingOrderPreSedationTh) {
            operatingOrderDataPreSedationTh = resultOperatingOrderPreSedationTh[0];
          }
        } else {
          operatingOrderDataPreSedationTh = null;
        }

        if (
          operatingOrderDataPreSedationTh &&
          operatingOrderDataPreSedationTh.anesthesia_teams[0]
        ) {
          anesthesiaPreSedationThId =
            operatingOrderDataPreSedationTh.anesthesia_teams[0]?.anesthesiologist;
        } else {
          anesthesiaPreSedationThId = null;
        }

        // Anes Check
        if (anesthesiaPreSedationThId) {
          // Find Anesthesiologist Name
          const resultAnesthesiaPreSedationThData = await getDoctorDetail({
            doctorId: anesthesiaPreSedationThId,
          });

          if (resultAnesthesiaPreSedationThData) {
            anesthesiaPreSedationThData = resultAnesthesiaPreSedationThData[0];
          }

          operatingOrderDetailPreSedationTh = {
            operation_summary: operatingOrderDataPreSedationTh.pre_operation_summary,
            diagnosis_summary: operatingOrderDataPreSedationTh.preoperative_diagnosis_summary,
            anes_name: anesthesiaPreSedationThData.full_name,
          };

          operationRegEx =
            operatingOrderDetailPreSedationTh.operation_summary.match(/1\. ([^\n]+)/);
          icd9RegEx = operatingOrderDetailPreSedationTh.operation_summary.match(/2\. ([^\n]+)/);

          pdfData = {
            items: {
              ...props.patientInfo,
              patientEncounter: props.encounterNumber
                ? props.encounterNumber
                : props.patientInfo?.encounter_no
                ? props.patientInfo?.encounter_no
                : "-",
              operatingData: operatingOrderDetailPreSedationTh,
              formatPatient: patientNameInFormTH,
              formatDoctor: doctorNameInFormTH,
              formatOperation: operationRegEx[1],
              formatIcd9: icd9RegEx[1],
              formatIcd10: operatingOrderDetailPreSedationTh.diagnosis_summary.slice(3),
              formatAnes: operatingOrderDetailPreSedationTh.anes_name.slice(3),
              ageYear: patientAge[0],
              ageMonth: patientAge[1],
              ageDay: patientAge[2],
              hospital: hospitalName,
              signedDate: printedDateUsageTh[0],
              signedTime: `${printedDateUsageTh[1]} น.`,
              haveHeader: isEnableHeader, // For Using Checkbox
            },
          };
        } else {
          operatingOrderDetailPreSedationTh = {
            operation_summary: operatingOrderDataPreSedationTh
              ? operatingOrderDataPreSedationTh.pre_operation_summary
                ? operatingOrderDataPreSedationTh.pre_operation_summary
                : " "
              : " ",
            diagnosis_summary: operatingOrderDataPreSedationTh
              ? operatingOrderDataPreSedationTh.preoperative_diagnosis_summary
                ? operatingOrderDataPreSedationTh.preoperative_diagnosis_summary
                : " "
              : " ",
            anes_name: " ",
          };

          if (operatingOrderDetailPreSedationTh.operation_summary) {
            operationRegEx =
              operatingOrderDetailPreSedationTh.operation_summary.match(/1\. ([^\n]+)/);
            icd9RegEx = operatingOrderDetailPreSedationTh.operation_summary.match(/2\. ([^\n]+)/);
          } else {
            operationRegEx = " ";
            icd9RegEx = " ";
          }
          // console.log("Print List operatingRegEx: ", operationRegEx);
          // console.log("Print List icd9RegEx: ", icd9RegEx);

          pdfData = {
            items: {
              ...props.patientInfo,
              patientEncounter: props.encounterNumber
                ? props.encounterNumber
                : props.patientInfo?.encounter_no
                ? props.patientInfo?.encounter_no
                : "-",
              operatingData: operatingOrderDetailPreSedationTh,
              formatPatient: patientNameInFormTH,
              formatDoctor: doctorNameInFormTH,
              // formatOperation: operationRegEx[1]
              //   ? operationRegEx[1]
              //   : operationRegEx,
              formatOperation: operationRegEx ? operationRegEx : " ",
              // formatIcd9: icd9RegEx[1] ? icd9RegEx[1] : icd9RegEx ? icd9RegEx : " ",
              formatIcd9: icd9RegEx ? icd9RegEx : " ",
              formatIcd10: operatingOrderDetailPreSedationTh.diagnosis_summary
                ? operatingOrderDetailPreSedationTh.diagnosis_summary.slice(3)
                : " ",
              formatAnes: operatingOrderDetailPreSedationTh.anes_name
                ? operatingOrderDetailPreSedationTh.anes_name
                : " ",
              ageYear: patientAge[0],
              ageMonth: patientAge[1],
              ageDay: patientAge[2],
              hospital: hospitalName,
              signedDate: printedDateUsageTh[0],
              signedTime: `${printedDateUsageTh[1]} น.`,
              haveHeader: isEnableHeader, // For Using Checkbox
            },
          };
        }

        // console.log("Print List OperatingObj: ", operatingInfo);
        // console.log("Print List PDFData: ", pdfData);

        docPdfDef = await FormConsentPreSedationTH(pdfData);

        if (isEnableHeader) {
          docPdfDef = await AddHeaderPrintListTH(docPdfDef, {
            createdDate: printedDateFormTh,
            formName:
              "หนังสือยินยอมก่อนการให้ยาระงับปวด\nหรือยาระงับความรู้สึกตัวการทำหัตถการ/การผ่าตัด",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "cons_pre_sedat_en":
        let anesthesiaPreSedationEnId,
          anesthesiaPreSedationEnData,
          operatingOrderDetailPreSedationEn,
          operatingOrderDataPreSedationEn = null;

        // get Hospital Name
        let resultHospitalNameForPreSedatEn = await getHospitalNameForPrintList({
          language: LANGUAGE_USAGE.EN,
        });
        if (resultHospitalNameForPreSedatEn) {
          hospitalName = resultHospitalNameForPreSedatEn[0]?.result?.core_HOSPITAL_NAME_EN;
        } else {
          hospitalName = " ";
        }

        if (patientEmrId) {
          const resultOperatingOrderPreSedationEn = await getOperatingOrderDetailByEmr({
            emrId: patientEmrId,
          });
          if (resultOperatingOrderPreSedationEn) {
            operatingOrderDataPreSedationEn = resultOperatingOrderPreSedationEn[0];
            // console.log(
            //   "Print List Operating Order Data (Pre Sedation EN): ",
            //   operatingOrderDataPreSedationEn
            // );
          }
        } else {
          operatingOrderDataPreSedationEn = null;
        }

        if (
          operatingOrderDataPreSedationEn &&
          operatingOrderDataPreSedationEn.anesthesia_teams[0]
        ) {
          anesthesiaPreSedationEnId =
            operatingOrderDataPreSedationEn.anesthesia_teams[0]?.anesthesiologist;
        } else {
          anesthesiaPreSedationEnId = null;
        }

        // Anes Check
        if (anesthesiaPreSedationEnId) {
          // Find Anesthesiologist Name
          const resultAnesthesiaPreSedationEnData = await getDoctorDetail({
            doctorId: anesthesiaPreSedationEnId,
          });

          if (resultAnesthesiaPreSedationEnData) {
            anesthesiaPreSedationEnData = resultAnesthesiaPreSedationEnData[0];
          }

          // Format Anesthesia Name
          const anesNameWithPrefix = `${anesthesiaPreSedationEnData?.full_name}`;
          const anesNameWithoutPrefixEN = `${anesthesiaPreSedationEnData?.first_name_en} ${anesthesiaPreSedationEnData?.last_name_en}`;
          const anesNameWithoutPrefixTH = `${anesthesiaPreSedationEnData?.first_name} ${anesthesiaPreSedationEnData?.last_name}`;
          const anesNameInFormEN =
            anesNameWithoutPrefixEN !== " "
              ? anesNameWithoutPrefixEN
              : anesNameWithoutPrefixTH !== " "
              ? anesNameWithoutPrefixTH
              : anesNameWithPrefix;

          // console.log("Pre Sedation EN Operating Data: ", operatingOrderDataPreSedationEn);

          // Get Treatment Data
          const treatmentCodeReEx =
            operatingOrderDataPreSedationEn.pre_operation_summary.match(/\[([^\]]+)\]/);

          if (treatmentCodeReEx) {
            var treatmentCode = treatmentCodeReEx[1];

            var treatmentDetailData = await getTreatmentDetail({
              code: treatmentCode,
            });
          } else {
            treatmentCode = null;
          }

          // console.log("Pre Sedation EN Treatment Code: ", treatmentCode);
          // console.log("Pre Sedation EN Treatment Data: ", treatmentDetailData);

          operatingOrderDetailPreSedationEn = {
            operation_summary: operatingOrderDataPreSedationEn.pre_operation_summary,
            diagnosis_summary: operatingOrderDataPreSedationEn.preoperative_diagnosis_summary,
            anes_name: anesNameInFormEN,
          };

          operationRegEx =
            operatingOrderDetailPreSedationEn.operation_summary.match(/1\. ([^\n]+)/);
          icd9RegEx = operatingOrderDetailPreSedationEn.operation_summary.match(/2\. ([^\n]+)/);

          const formatOperationEN =
            treatmentDetailData?.[0]?.items?.[0]?.name_en.trim().length > 0
              ? `[${treatmentCode}] ` + treatmentDetailData?.[0]?.items?.[0]?.name_en
              : treatmentDetailData?.[0]?.items?.[0]?.name.trim().length > 0
              ? `[${treatmentCode}] ` + treatmentDetailData?.[0]?.items?.[0]?.name
              : treatmentDetailData?.[0]?.items?.[0]?.name_code !== null
              ? treatmentDetailData?.[0]?.items?.[0]?.name_code
              : " ";

          pdfData = {
            items: {
              ...props.patientInfo,
              patientBirthdateEN: patientBirthdateFormEN,
              patientEncounter: props.encounterNumber
                ? props.encounterNumber
                : props.patientInfo?.encounter_no
                ? props.patientInfo?.encounter_no
                : "-",
              operatingData: operatingOrderDetailPreSedationEn,
              formatPatient: patientNameInFormEN,
              formatDoctor: doctorNameInFormEN,
              formatOperation: formatOperationEN,
              formatIcd9: icd9RegEx[1],
              formatIcd10: operatingOrderDetailPreSedationEn.diagnosis_summary.slice(3),
              formatAnes: operatingOrderDetailPreSedationEn.anes_name,
              ageYear: patientAge[0],
              ageMonth: patientAge[1],
              ageDay: patientAge[2],
              hospital: hospitalName,
              signedDate: printedDateUsageEn[0],
              signedTime: printedDateUsageEn[1],
              haveHeader: isEnableHeader, // For Using Checkbox
            },
          };
        } else {
          operatingOrderDetailPreSedationEn = {
            operation_summary: operatingOrderDataPreSedationEn
              ? operatingOrderDataPreSedationEn.pre_operation_summary
                ? operatingOrderDataPreSedationEn.pre_operation_summary
                : " "
              : " ",
            diagnosis_summary: operatingOrderDataPreSedationEn
              ? operatingOrderDataPreSedationEn.preoperative_diagnosis_summary
                ? operatingOrderDataPreSedationEn.preoperative_diagnosis_summary
                : " "
              : " ",
            anes_name: " ",
          };

          if (operatingOrderDetailPreSedationEn.operation_summary) {
            operationRegEx =
              operatingOrderDetailPreSedationEn.operation_summary.match(/1\. ([^\n]+)/);
            icd9RegEx = operatingOrderDetailPreSedationEn.operation_summary.match(/2\. ([^\n]+)/);
          } else {
            operationRegEx = " ";
            icd9RegEx = " ";
          }

          pdfData = {
            items: {
              ...props.patientInfo,
              patientBirthdateEN: patientBirthdateFormEN,
              patientEncounter: props.encounterNumber
                ? props.encounterNumber
                : props.patientInfo?.encounter_no
                ? props.patientInfo?.encounter_no
                : "-",
              operatingData: operatingOrderDetailPreSedationEn,
              formatPatient: patientNameInFormEN,
              formatDoctor: doctorNameInFormEN,
              // formatOperation: operationRegEx[1]
              //   ? operationRegEx[1]
              //   : operationRegEx ? operationRegEx : " ",
              formatOperation: operationRegEx ? operationRegEx : " ",
              // formatIcd9: icd9RegEx[1] ? icd9RegEx[1] : icd9RegEx ? icd9RegEx : " ",
              formatIcd9: icd9RegEx ? icd9RegEx : " ",
              formatIcd10: operatingOrderDetailPreSedationEn.diagnosis_summary
                ? operatingOrderDetailPreSedationEn.diagnosis_summary.slice(3)
                : " ",
              formatAnes: operatingOrderDetailPreSedationEn.anes_name
                ? operatingOrderDetailPreSedationEn.anes_name
                : " ",
              ageYear: patientAge[0],
              ageMonth: patientAge[1],
              ageDay: patientAge[2],
              hospital: hospitalName,
              signedDate: printedDateUsageEn[0],
              signedTime: printedDateUsageEn[1],
              haveHeader: isEnableHeader, // For Using Checkbox
            },
          };
        }

        // console.log("Print List OperatingObj: ", operatingInfo);
        // console.log("Print List PDFData: ", pdfData);

        docPdfDef = await FormConsentPreSedationEN(pdfData);

        if (isEnableHeader) {
          docPdfDef = await AddHeaderPrintListEN(docPdfDef, {
            createdDate: printedDateFormEn,
            formName: "Pre Procedural Sedation and Pre Anesthesia\nInformed Consent",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "cons_operating_th":
        let consOperatingOrderDetailTh,
          consOperatingOrderDataTh = null;

        if (patientEmrId) {
          const resultConsOperatingOrderDetailTh = await getOperatingOrderDetailByEmr({
            emrId: patientEmrId,
          });

          if (resultConsOperatingOrderDetailTh) {
            consOperatingOrderDataTh = resultConsOperatingOrderDetailTh[0];
          }

          consOperatingOrderDetailTh = {
            operation_summary: consOperatingOrderDataTh.pre_operation_summary,
            diagnosis_summary: consOperatingOrderDataTh.preoperative_diagnosis_summary,
          };
        } else {
          consOperatingOrderDetailTh = {
            operation_summary: null,
            diagnosis_summary: null,
          };
        }

        // get Operating Order Data, ICD9, ICD10
        if (
          consOperatingOrderDetailTh.operation_summary &&
          consOperatingOrderDetailTh.diagnosis_summary
        ) {
          operationRegEx = consOperatingOrderDetailTh.operation_summary
            .match(/1\. ([^\n]+)/)[1]
            .concat(",");
          icd9RegEx = consOperatingOrderDetailTh.operation_summary
            .match(/2\. ([^\n]+)/)[1]
            .concat(",");
          icd10RegEx = consOperatingOrderDetailTh.diagnosis_summary.match(/1\. ([^\n]+)/)[1];
        } else {
          operationRegEx = " ";
          icd9RegEx = " ";
          icd10RegEx = " ";
        }

        let operatingAllData = `${operationRegEx} ${icd9RegEx} ${icd10RegEx}`;

        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.encounterNumber
              ? props.encounterNumber
              : props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : "-",
            operatingData: consOperatingOrderDetailTh,
            formatPatient: patientNameInFormTH,
            formatDoctor: doctorNameInFormTH,
            enableDecorationOperation: operatingAllData.trim().length > 0,
            formatOperation:
              operatingAllData.trim().length > 0
                ? operatingAllData
                : "...................................................................................................................................................................................................................\n...................................................................................................................................................................................................................",
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
            haveHeader: isEnableHeader,
          },
        };
        // console.log("Print List pdfData: ", pdfData);

        docPdfDef = await FormConsentOperatingTH(pdfData);

        if (isEnableHeader) {
          docPdfDef = await AddHeaderPrintListTH(docPdfDef, {
            createdDate: printedDateFormTh,
            formName: "หนังสือแสดงความยินยอมการผ่าตัดหรือการทำหัตถการพิเศษ",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "cons_treat_proc":
        let diagnosisTreatmentProcedureInfo,
          diagnosisTreatmentProcedureData = null;

        if (patientEmrId) {
          // Call API Diagnosis
          const resultDiagnosisTreatmentProcedure = await getDiagnosisMedicalRecordDetail({
            emrId: patientEmrId,
          });

          if (resultDiagnosisTreatmentProcedure) {
            diagnosisTreatmentProcedureData = resultDiagnosisTreatmentProcedure[0];
          }
        } else {
          diagnosisTreatmentProcedureData = null;
        }

        if (diagnosisTreatmentProcedureData) {
          diagnosisTreatmentProcedureInfo = {
            principal_diagnosis: diagnosisTreatmentProcedureData.principal_diagnosis?.[0],
            icd10_code: diagnosisTreatmentProcedureData.principal_diagnosis?.[0]?.icd_code,
            icd10_term: diagnosisTreatmentProcedureData.principal_diagnosis?.[0]?.icd_term,
            icd10_med_term:
              diagnosisTreatmentProcedureData.principal_diagnosis?.[0]?.icd10_med_term,
          };

          pdfData = {
            items: {
              ...props.patientInfo,
              patientEncounter: props.encounterNumber
                ? props.encounterNumber
                : props.patientInfo?.encounter_no
                ? props.patientInfo?.encounter_no
                : "-",
              diagnosisData: diagnosisTreatmentProcedureInfo,
              formatIcd10Code:
                diagnosisTreatmentProcedureInfo.icd10_code === "" ||
                diagnosisTreatmentProcedureInfo.icd10_code == null
                  ? " "
                  : diagnosisTreatmentProcedureInfo.icd10_code,
              formatIcd10Term:
                diagnosisTreatmentProcedureInfo.icd10_term === "" ||
                diagnosisTreatmentProcedureInfo.icd10_term == null
                  ? " "
                  : diagnosisTreatmentProcedureInfo.icd10_term,
              printDay: createdFormDate[0],
              printMonth: createdFormDate[2],
              printYear: createdFormDate[3],
              printTime: createdFormDate[4],
              formatPatient: patientNameInFormTH,
              formatDoctor: doctorNameInFormTH,
              ageYear: patientAge[0],
              ageMonth: patientAge[1],
              ageDay: patientAge[2],
              haveHeader: isEnableHeader,
            },
          };
        } else {
          pdfData = {
            items: {
              ...props.patientInfo,
              patientEncounter: props.encounterNumber
                ? props.encounterNumber
                : props.patientInfo?.encounter_no
                ? props.patientInfo?.encounter_no
                : "-",
              diagnosisData: " ",
              formatIcd10Code: " ",
              formatIcd10Term: " ",
              printDay: createdFormDate[0],
              printMonth: createdFormDate[2],
              printYear: createdFormDate[3],
              printTime: createdFormDate[4],
              formatPatient: patientNameInFormTH,
              formatDoctor: doctorNameInFormTH,
              ageYear: patientAge[0],
              ageMonth: patientAge[1],
              ageDay: patientAge[2],
              haveHeader: isEnableHeader,
            },
          };
        }
        // console.log("Print List pdfData: ", pdfData);

        docPdfDef = await FormConsentTreatmentProcedure(pdfData);

        if (isEnableHeader) {
          docPdfDef = await AddHeaderPrintListTH(docPdfDef, {
            createdDate: printedDateFormTh,
            formName: "หนังสือรับทราบ และยินยอมรับการรักษา ฟื้นฟู หรือทำหัตถการ",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "new_patient_register":
        // get Hospital Name
        let resultHospitalName = await getHospitalNameForPrintList({ language: LANGUAGE_USAGE.TH });
        if (resultHospitalName) {
          hospitalName = resultHospitalName[0]?.result?.core_HOSPITAL_NAME;
        } else {
          hospitalName = " ";
        }

        // get Patient Allegy
        let resultPatientAllergy = await getPatientAllergyForPrintList({
          patientId: props.patientInfo?.id,
        });
        if (resultPatientAllergy) {
          patientAllergy = resultPatientAllergy[0]?.items
            ?.filter((item: any) => item.status !== "NO_REACTION" && item.name.length > 0)
            .map((item: any) => {
              if (item.type_name_name === "DRUG") {
                item.name = `${item.name} (${item.adversary_type?.toLowerCase()})`;
              }
              return item.name;
            })
            .join(", ");
        } else {
          patientAllergy = " ";
        }

        // RegDataList
        const regData = await getRegDataList();

        // params use
        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.encounterNumber
              ? props.encounterNumber
              : props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : "-",
            haveHeader: isEnableHeader,
            printDay: createdFormDate[0],
            printMonth: createdFormDate[2],
            printYear: createdFormDate[3],
            printTime: createdFormDate[4],
            formatPatient: patientNameInFormTH,
            formatDoctor: doctorNameInFormTH,
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
            hospital: hospitalName,
            allergy: patientAllergy,
            regData: regData,
            citizen_split:
              props.patientInfo?.citizen_no.length > 0 &&
              props.patientInfo?.citizen_no !== "Non citizen identity"
                ? props.patientInfo?.citizen_no?.split("")
                : [],
            zipcode_split:
              props.patientInfo?.present_address !== null &&
              Object.keys(props.patientInfo?.present_address).length > 0
                ? props.patientInfo?.present_address?.zipcode.split("")
                : [],
            owner_split:
              props.patientInfo?.relative_address !== null &&
              Object.keys(props.patientInfo?.relative_address).length > 0
                ? props.patientInfo?.relative_address?.owner_name.split(" ")
                : [],
            relative_zipcode_split:
              props.patientInfo?.relative_address !== null &&
              Object.keys(props.patientInfo?.relative_address).length > 0
                ? props.patientInfo?.relative_address?.zipcode.split("")
                : [],
          },
        };

        docPdfDef = await FormNewPatientRegistration(pdfData);

        // ใช้ Header Sapiens Info
        if (isEnableHeader) {
          docPdfDef = await AddHeaderSapiensInfoTH(docPdfDef, {
            createdDate: printedDateFormTh,
          });
        }
        break;
      case "patient_sticker":
        // param use
        pdfData = {
          items: { ...props.patientInfo },
        };

        docPdfDef = FormPatientStickerSingle(pdfData);

        break;
      case "consent_ama_th":
        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.encounterNumber
              ? props.encounterNumber
              : props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : "-",
            haveHeader: isEnableHeader,
            printDay: createdFormDate[0],
            printMonth: createdFormDate[2],
            printYear: createdFormDate[3],
            printTime: createdFormDate[4],
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
            formatPatient: patientNameInFormTH,
            signedDate: printedDateUsageTh[0],
            signedTime: `${printedDateUsageTh[1]} น.`,
          },
        };

        docPdfDef = FormConsentAMATH(pdfData);

        if (isEnableHeader) {
          docPdfDef = await AddHeaderPrintListTH(docPdfDef, {
            createdDate: printedDateFormTh,
            formName: "หนังสือการปฏิเสธการรักษา",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "consent_ama_en":
        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.encounterNumber
              ? props.encounterNumber
              : props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : "-",
            patientBirthdateEN: patientBirthdateFormEN,
            haveHeader: isEnableHeader,
            printDay: createdFormDate[0],
            printMonth: createdFormDate[2],
            printYear: createdFormDate[3],
            printTime: createdFormDate[4],
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
            formatPatient: patientNameInFormEN,
            signedDate: printedDateUsageEn[0],
            signedTime: printedDateUsageEn[1],
          },
        };

        docPdfDef = FormConsentAMAEN(pdfData);

        if (isEnableHeader) {
          docPdfDef = await AddHeaderPrintListEN(docPdfDef, {
            createdDate: printedDateFormEn,
            formName: "Consent : Against Medical Advice",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "dnr_th":
        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.encounterNumber
              ? props.encounterNumber
              : props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : "-",
            haveHeader: isEnableHeader,
            printDay: createdFormDate[0],
            printMonth: createdFormDate[2],
            printYear: createdFormDate[3],
            printTime: createdFormDate[4],
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
            formatPatient: patientNameInFormTH,
            signedDate: printedDateUsageTh[0],
            signedTime: `${printedDateUsageTh[1]} น.`,
          },
        };

        docPdfDef = FormDNRTH(pdfData);

        if (isEnableHeader) {
          docPdfDef = await AddHeaderPrintListTH(docPdfDef, {
            createdDate: printedDateFormTh,
            formName: "Do Not Resuscitate Request (TH)",
            patientInfo: pdfData.items,
            footer: "แบบฟอร์มนี้ใช้สำหรับ admission ต่อครั้งเท่านั้น",
          });
        }
        break;
      case "dnr_en":
        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.encounterNumber
              ? props.encounterNumber
              : props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : "-",
            patientBirthdateEN: patientBirthdateFormEN,
            formatDoctor: doctorNameInFormEN,
            haveHeader: isEnableHeader,
            printDay: createdFormDateEn[0],
            printMonth: createdFormDateEn[2],
            printYear: createdFormDateEn[3],
            printTime: createdFormDateEn[4],
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
            formatPatient: patientNameInFormEN,
            signedDate: printedDateUsageEn[0],
            signedTime: printedDateUsageEn[1],
          },
        };

        docPdfDef = FormDNREN(pdfData);

        if (isEnableHeader) {
          docPdfDef = await AddHeaderPrintListEN(docPdfDef, {
            createdDate: printedDateFormEn,
            formName: "Do Not Resuscitate Request (EN)",
            patientInfo: pdfData.items,
            footer: `This form should be applied "Only One Time of Admission"`,
          });
        }
        break;
      case "living_will_th":
        // get Doctor Cert Number
        let doctorCertNumberTh: any = null;
        let patientNationalityTh: any = null;

        if (props.patientInfo?.doctor_name || props.patientInfo?.doctor_name !== "") {
          let getDoctorId = props.masterOptions?.doctor
            .filter((item: any) => {
              return new RegExp(props.patientInfo?.doctor_name).test(item.text);
            })
            .map((item: any) => item.value);

          let getDoctorCertNumber = await getDoctorDetail({
            doctorId: getDoctorId[0],
          });

          if (getDoctorCertNumber) {
            doctorCertNumberTh = getDoctorCertNumber[0]?.certificate_no;
          } else {
            doctorCertNumberTh = null;
          }
        } else {
          doctorCertNumberTh = null;
        }

        // get Patient Nationality
        if (props.patientInfo?.nationality) {
          let getNationText = props.masterOptions?.nationality
            .filter((item: any) => {
              return item.value === props.patientInfo?.nationality;
            })
            .map((item: any) => item.text);

          if (getNationText) {
            let splitNation = getNationText[0].split(" ");
            patientNationalityTh = splitNation[splitNation.length - 1];
          } else {
            patientNationalityTh = null;
          }
        } else {
          patientNationalityTh = null;
        }

        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.encounterNumber
              ? props.encounterNumber
              : props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : "-",
            haveHeader: isEnableHeader,
            printDay: createdFormDate[0],
            printMonth: createdFormDate[2],
            printYear: createdFormDate[3],
            printTime: createdFormDate[4],
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
            formatPatient: patientNameInFormTH,
            formatDoctor: doctorNameInFormTH,
            patientNationality: patientNationalityTh,
            doctorNumber: doctorCertNumberTh,
            signedDate: printedDateUsageTh[0],
            signedTime: `${printedDateUsageTh[1]} น.`,
          },
        };

        docPdfDef = FormLivingWillTH(pdfData);

        if (isEnableHeader) {
          docPdfDef = await AddHeaderPrintListTH(docPdfDef, {
            createdDate: printedDateFormTh,
            formName: "Living Will (TH)",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "living_will_en":
        // get Doctor Cert Number
        let doctorCertNumberEn: any = null;
        let patientNationalityEn: any = null;

        if (props.patientInfo?.doctor_name || props.patientInfo?.doctor_name !== "") {
          let getDoctorId = props.masterOptions?.doctor
            .filter((item: any) => {
              return new RegExp(props.patientInfo?.doctor_name).test(item.text);
            })
            .map((item: any) => item.value);

          let getDoctorCertNumber = await getDoctorDetail({
            doctorId: getDoctorId[0],
          });

          if (getDoctorCertNumber) {
            doctorCertNumberEn = getDoctorCertNumber[0]?.certificate_no;
          } else {
            doctorCertNumberEn = null;
          }
        } else {
          doctorCertNumberEn = null;
        }

        // get Patient Nationality
        if (props.patientInfo?.nationality) {
          let getNationText = props.masterOptions?.nationality
            .filter((item: any) => {
              return item.value === props.patientInfo?.nationality;
            })
            .map((item: any) => item.text);

          if (getNationText) {
            let splitNation = getNationText[0].split(" ");
            patientNationalityEn = splitNation[0];
          } else {
            patientNationalityEn = null;
          }
        } else {
          patientNationalityEn = null;
        }

        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.encounterNumber
              ? props.encounterNumber
              : props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : "-",
            haveHeader: isEnableHeader,
            printDay: createdFormDate[0],
            printMonth: createdFormDate[2],
            printYear: createdFormDate[3],
            printTime: createdFormDate[4],
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
            patientNationality: patientNationalityEn,
            doctorNumber: doctorCertNumberEn,
            formatDoctor: doctorNameInFormEN,
            formatPatient: patientNameInFormEN,
            signedDate: printedDateUsageEn[0],
            signedTime: printedDateUsageEn[1],
          },
        };

        docPdfDef = FormLivingWillEN(pdfData);

        if (isEnableHeader) {
          docPdfDef = await AddHeaderPrintListEN(docPdfDef, {
            createdDate: printedDateFormEn,
            formName: "Living Will (EN)",
            patientInfo: pdfData.items,
          });
        }
        break;
      case "price_evaluate":
        // get Doctor Cert Number
        let doctorCertNumberPriceEvaluate: any = null;

        if (props.patientInfo?.doctor_name || props.patientInfo?.doctor_name !== "") {
          let getDoctorId = props.masterOptions?.doctor
            .filter((item: any) => {
              return new RegExp(props.patientInfo?.doctor_name).test(item.text);
            })
            .map((item: any) => item.value);

          let getDoctorCertNumber = await getDoctorDetail({
            doctorId: getDoctorId[0],
          });

          if (getDoctorCertNumber) {
            doctorCertNumberPriceEvaluate = getDoctorCertNumber[0]?.certificate_no;
          } else {
            doctorCertNumberPriceEvaluate = null;
          }
        } else {
          doctorCertNumberPriceEvaluate = null;
        }

        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.encounterNumber
              ? props.encounterNumber
              : props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : "-",
            doctorNumber: doctorCertNumberPriceEvaluate,
            formatDoctor: doctorNameInFormEN,
            formatPatient: patientNameInFormEN,
          }
        };

        docPdfDef = await FormPriceEvaluate(pdfData);

        break;
      case "price_evaluate_th":
        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.encounterNumber
              ? props.encounterNumber
              : props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : "-",
            printDay: createdFormDate[0],
            printMonth: createdFormDate[2],
            printYear: createdFormDate[3],
            printTime: createdFormDate[4],
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
            formatPatient: patientNameInFormTH,
            formatDoctor: doctorNameInFormTH,
            signedDate: printedDateUsageTh[0],
            formatGender: GENDER_LIST[props.patientInfo?.gender].name_th || props.patientInfo?.gender,
          },
        };

        docPdfDef = await FormPriceEvaluateTH(pdfData);

        break;
      case "price_evaluate_en":
        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.encounterNumber
              ? props.encounterNumber
              : props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : "-",
            printDay: createdFormDate[0],
            printMonth: createdFormDate[2],
            printYear: createdFormDate[3],
            printTime: createdFormDate[4],
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
            formatPatient: patientNameInFormEN,
            patientBirthdateEN: patientBirthdateFormEN,
            formatDoctor: doctorNameInFormEN,
            signedDate: printedDateUsageEn[0],
            formatGender: GENDER_LIST[props.patientInfo?.gender].name_en || props.patientInfo?.gender,
          },
        };

        docPdfDef = await FormPriceEvaluateEN(pdfData);

        break;
      case "cons_operating_en":
        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.encounterNumber
              ? props.encounterNumber
              : props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : "-",
            printDay: createdFormDate[0],
            printMonth: createdFormDate[2],
            printYear: createdFormDate[3],
            printTime: createdFormDate[4],
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
            formatPatient: patientNameInFormEN,
            patientBirthdateEN: patientBirthdateFormEN,
            formatDoctor: doctorNameInFormEN,
            signedDate: printedDateUsageEn[0],
            formatGender: GENDER_LIST[props.patientInfo?.gender].name_en || props.patientInfo?.gender,
          },
        };

        docPdfDef = await FormConsentOperatingEN(pdfData);
        break;
      case "price_estimate_th":
        // get Patient Allegy
        let priceEstimatePatientAllergy = await getPatientAllergyForPrintList({
          patientId: props.patientInfo?.id,
        });
        if (priceEstimatePatientAllergy) {
          patientAllergy = priceEstimatePatientAllergy[0]?.items
            ?.filter((item: any) => item.status !== "NO_REACTION" && item.name.length > 0)
            .map((item: any) => {
              if (item.type_name_name === "DRUG") {
                item.name = `${item.name} (${item.adversary_type?.toLowerCase()})`;
              }
              return item.name;
            })
            .join(", ");
        } else {
          patientAllergy = " ";
        }

        // console.log("Yeti Patient Allergy: ", {full_data: priceEstimatePatientAllergy, format: patientAllergy});

        // get OperatingOrder Data
        let priceEstimateOperatingOrderData = null;
        if (patientEmrId) {
          const resultOperatingOrderPreSedationTh = await getOperatingOrderDetailByEmr({
            emrId: patientEmrId,
          });
          if (resultOperatingOrderPreSedationTh) {
            priceEstimateOperatingOrderData = resultOperatingOrderPreSedationTh[0];
          }
        } else {
          priceEstimateOperatingOrderData = null;
        }

        // console.log("Yeti Patient Operating Order Data: ", priceEstimateOperatingOrderData);

        pdfData = {
          items: {
            ...props.patientInfo,
            patientEncounter: props.encounterNumber
              ? props.encounterNumber
              : props.patientInfo?.encounter_no
              ? props.patientInfo?.encounter_no
              : "-",
            printDay: createdFormDate[0],
            printMonth: createdFormDate[2],
            printYear: createdFormDate[3],
            printTime: createdFormDate[4],
            ageYear: patientAge[0],
            ageMonth: patientAge[1],
            ageDay: patientAge[2],
            formatPatient: patientNameInFormTH,
            formatDoctor: doctorNameInFormTH,
            signedDate: printedDateUsageTh[0],
            formatGender: GENDER_LIST[props.patientInfo?.gender].name_th || props.patientInfo?.gender,
            patientAllergy: patientAllergy,
            attendingDoctor: priceEstimateOperatingOrderData?.primary_doctor_name,
            operatingRoom: priceEstimateOperatingOrderData?.operating_room_no,
          },
        };

        docPdfDef = await FormPriceEstimateTH(pdfData);

        break;
      default: {
        const pathFile =
          originalRow.jasper_path || FALLBACK_PDF_PATHS[originalRow.code];

        if (pathFile) {
          docPdfDef = { pathFile };
        } else {
          clear();
        }
        break;
      }
    }

    // console.log("Print List PDF Data: ", pdfData);

    if (isMounted.current) {
      setIsLoading(false);
      if (docPdfDef && print === false) {
        handleGetPdfMakeResult(docPdfDef);
      } else if (docPdfDef && docPdfDef.blobRaw) {
        const blob = docPdfDef.blobRaw;

        if (blob) {
          printURL(URL.createObjectURL(blob));
          setOpenModInfoTask(false);
          setModInfoText("พิมพ์รายการสำเร็จ");
          setModInfoTitle("สำเร็จ");
          setModInfoTitleColor("teal");
          setOpenModInfo(true);
          clear();
        }
      } else if (docPdfDef && print === true) {
        // (await getPdfMake(true)).createPdf(docPdfDef).print();
        const pdfMake = await getPdfMake(true);
        const pdf = pdfMake.createPdf(docPdfDef);
        const pdfBlobFromPdf = new Promise<Blob>((resolve) => {
          pdf.getBlob((blob: any) => {
            resolve(blob);
          });
        });

        const blob: Blob | null = await(
          docPdfDef.pathFile ? getPdfBlob(docPdfDef.pathFile) : pdfBlobFromPdf
        );

        if (blob) {
          printURL(URL.createObjectURL(blob));
          setOpenModInfoTask(false);
          setModInfoText("พิมพ์รายการสำเร็จ");
          setModInfoTitle("สำเร็จ");
          setModInfoTitleColor("teal");
          setOpenModInfo(true);
          clear();
        }
      } else {
        setFile("about:blank" as any);
        console.error("PDF Data doesn't Exist");
      }
    }
  };

  const handleGetPdfMakeResult = async (data: any) => {
    if (data) {
      setOpenModInfo(false);

      let pdfB64Data = "";

      if (data.pathFile) {
        pdfB64Data = await getPdfBase64(data.pathFile);
      } else if (data.base64String) {
        pdfB64Data = data.base64String;
      } else {
        const pdfDocGen = (await getPdfMake(true)).createPdf(data);
        pdfB64Data = await new Promise<string>((resolve, reject) => {
          pdfDocGen.getBase64((data) => resolve(data));
        });
      }
      if (pdfB64Data) {
        // console.log("Yeti Base64: ", pdfB64Data);
        setFile(`data:application/pdf;base64,${pdfB64Data}`);
      }
    } else {
      console.error("Cannot Open PDF File!!");
    }
  };

  const handleGetDocumentResult = ({ data, preview }: any = {}) => {
    if (preview && data.success && data.pdf_b64data.length > 0) {
      setOpenModInfoTask(false);
      return setFile(("data:application/pdf;base64," + data.pdf_b64data) as any);
    } else if (!preview && data.success) {
      setOpenModInfoTask(false);
      setModInfoText("พิมพ์รายการสำเร็จ");
      setModInfoTitle("สำเร็จ");
      setModInfoTitleColor("teal");
      setOpenModInfo(true);
      clear();
    } else if (data.warning) {
      setFile("about:blank" as any);
      alert(data.warning);
    } else if (data.task_id) {
      setModInfoTaskText(
        (
          <div style={{ textAlign: "left", padding: 0 }}>
            <div>กรุณารอสักครู่ </div>For IT: task_id = {data.task_id}
          </div>
        ) as any
      );
      setOpenModInfoTask(true);
      setGetPrinted(false);
      setTaskTracking(data.task_id);
    } else {
      setOpenModInfoTask(false);
      handlePrintAPIFail({ error: data.error, preview });
    }
  };

  const handleCancelTask = () => {
    getTaskTracking({ action: "CANCEL" });
  };

  const handleApproveConfirm = () => {
    setModConfirmError(null);
    if (!(reasonRef as any).current.getValue()) {
      return setModConfirmError("กรุณาระบุเหตุผล" as any);
    }
    setOpenModConfirm(false);
    getPrintAPI({ originalRow: selectedRow, preview: false, confirm: true });
  };

  // Print
  const handlePrintReport = (originalRow: any) => {
    if (originalRow?.print_api !== "/pdfmake") {
      getPrintAPI({ originalRow: selectedRow, preview: false, confirm: false });
    } else {
      getPrintPDFMake({ originalRow: selectedRow, print: true });
    }
  };

  // Print List Data
  console.log("Print List Props: ", props);
  // console.table("Table of Props: ", props);

  return (
    <CardLayout
      titleText="พิมพ์เอกสาร"
      closeable={props.closeable}
      toggleable={props.toggleable}
      onClose={props.onClose}
      loading={isLoading}
    >
      <ModConfirm
        openModal={openModConfirm}
        className={"fixNotMiddleModal"}
        onDeny={() => setOpenModConfirm(false)}
        titleName={modConfirmTitle}
        onApprove={handleApproveConfirm}
        content={
          <Form>
            <ErrorMessage error={modConfirmError} />
            <Form.Group>
              <Form.Field width={16}>
                <label>
                  ระบุเหตุผล
                  <label style={{ color: "red" }}>*</label>
                </label>
                <ComboBox ref={reasonRef as any} options={reasonChoices} />
              </Form.Field>
            </Form.Group>
          </Form>
        }
      />
      <ModInfo
        className="fixNotMiddleModal"
        open={openModInfoTask}
        alertText={modInfoTaskText}
        titleName="กำลังเตรียมเอกสาร"
        btnText="ยกเลิก"
        titleColor="teal"
        buttonColor="red"
        textAlign={"left"}
        onApprove={handleCancelTask}
      />
      <ModInfo
        className="fixNotMiddleModal"
        open={openModInfo}
        titleColor={modInfoTitleColor}
        titleName={modInfoTitle}
        alertText={
          typeof modInfoText !== "string" ? <ErrorMessage error={modInfoText} /> : modInfoText
        }
        closeOnDimmerClick={true}
        onClose={() => setOpenModInfo(false)}
        onApprove={() => setOpenModInfo(false)}
      />
      <Modal
        open={openModEncounterList}
        closeOnDimmerClick={true}
        onClose={() => setOpenModEncounterList(false)}
        content={
          <CardEncounterList
            patientId={props.patientId}
            patientHn={props.patientInfo?.hn}
            controller={props.controller}
            onSelect={(selectedRow: any) => {
              (sbxEncounterRef as any).current.setText(selectedRow.number_date);
              (sbxEncounterRef as any).current.setId(selectedRow.id);
              handleSelectOption({ item: selectedRow });
              setOpenModEncounterList(false);
            }}
          />
        }
      />
      <PatientIdentification patientInfo={patientInfo} />
      <Form>
        <Form.Group inline>
          <Form.Field>
            <label>Encounter Number :</label>
          </Form.Field>
          <Form.Field>
            <SearchBox
              ref={sbxEncounterRef}
              testid="sbxEncounter"
              linkIcon
              onIconClick={() => setOpenModEncounterList(true)}
              minCharacters={2}
              textField="number_date"
              onGetSearchOptions={handleSearchEncounter}
              onSelectOption={handleSelectOption}
              onClear={() => setEncounterId(props.encounterId)}
              defaultOptions={encounterList}
              defaultId={props.encounterId ? props.encounterId : props.patientInfo?.encounter_id}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <br />
      <Grid>
        <Grid.Column width={4}>
          <Input
            fluid
            icon="search"
            placeholder="ค้นหาชื่อเอกสาร"
            onChange={handleSearchDocumentType}
            loading={isSearchDocumentLoading}
          />
          <br />
          <PureReactTable
            style={styles.docTable}
            showPagination={false}
            testid="grdRequest"
            data={documentType}
            onSelect={handleSelectDocType}
            selectedRow={selectedRow}
            selectedClassName="blueSelectedRow"
            columns={[
              {
                Header: "ชื่อเอกสาร",
                accessor: "name",
              },
            ]}
          />
        </Grid.Column>
        <Grid.Column width={12}>
          <Segment style={{ height: "500px" }}>
            <FrameChrome file={file} visible />
          </Segment>
        </Grid.Column>
      </Grid>
      <br />
      <Form>
        <Form.Group inline className="rightAlign">
          <Form.Field width={3}>
            <Button
              color="blue"
              content="พิมพ์"
              fluid
              // onClick={() => {
              //   getPrintAPI({
              //     originalRow: selectedRow,
              //     preview: false,
              //     confirm: false,
              //   });
              // }}
              onClick={() => {
                handlePrintReport(selectedRow);
              }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </CardLayout>
  );
});

const TASK_TRACKING_STATUS = {
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  CANCELED: "CANCELED",
  IN_PROGRESS: "IN_PROGRESS",
};

const styles = {
  docTable: {
    height: "500px",
  },
};

CardPrintList.propTypes = {
  toggleable: PropTypes.bool,
  closeable: PropTypes.bool,
  patientInfo: PropTypes.object,
  onClose: PropTypes.func,
  patientData: PropTypes.object,
  controller: PropTypes.object,
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  encounterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  encounterNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  operatingOrderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CardPrintList.defaultProps = {
  toggleable: false,
  closeable: true,
  patientInfo: {},
  onClose: () => {},
  patientData: {},
  controller: {},
  patientId: null,
  encounterId: null,
  encounterNumber: null,
  operatingOrderId: null,
};

CardPrintList.displayName = "CardPrintList";
export default React.memo(CardPrintList);
